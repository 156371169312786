import { Carousel } from 'libs/growth-platform-brand-system-v2/src/components/Carousel';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { toBoolean } from 'libs/growth-platform-brand-system-v2/src/utils/toBoolean';
import { useParams } from 'libs/growth-platform-brand-system-v2/src/utils/useParams';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { Pricing } from 'libs/growth-platform-brand-system-v2/src/components/Pricing';
import React, { useMemo } from 'react';

import { PaginationDots } from 'libs/growth-platform-brand-system-v2/src/components/Carousel/PaginationDots';
import { morphism } from 'morphism';
import { schemaPricing } from '../../../morphismSchema/components/schemaPricing';
import { OffersAssociated } from '../lib/Offer/types';
import {
  findDiscountOfferFromAssociatedOffers,
  getCardsDisabled,
  getFirstPayrollOffer,
  hasDiscountOffers,
  useAssociatedOffers,
} from '../lib/utils';
import { Card } from './Card';

export const TablePrice = ({ doc, locale, setOpenTablePrice }: any) => {
  const screen = useScreenSize();
  const [params] = useParams();
  const nbEmployee = Number(params.nbEmployee) || 1;
  const associatedOffers = useAssociatedOffers(doc);
  const prefixTotal = doc.prefixTotalLabel;
  const sufixTotal = doc.sufixTotalLabel;
  const firstPayroll = doc.firstPayroll?.[0];
  const isFirstPayroll = toBoolean(params?.firstPayroll || 'false');
  const numberEmployeesInput = doc.numberEmployeesInput?.[0];
  const numberEmployeesInputLabel = numberEmployeesInput?.label;
  const buttonList = numberEmployeesInput?.buttonsList;
  const initialValue = buttonList?.length > 0 ? buttonList[0]?.value : 1;
  const comparaisonTableLink = doc.comparaisonTableLink;
  const prefixPricePerEmployee = doc.prefixPricePerEmployee;
  const hideTotalPrice = doc.hideTotalPrice;
  const atLeastOneDiscountOfferIsDisplayed = associatedOffers.some(
    (associatedOffer) =>
      associatedOffer.discountOffers?.some(
        (discountOffer) =>
          discountOffer.minEmployees <= nbEmployee &&
          discountOffer.maxEmployees >= nbEmployee,
      ),
  );

  const pricing = morphism(schemaPricing, { ...doc, nbEmployee });
  const { cta } = pricing;

  const cardsDisabled = getCardsDisabled(isFirstPayroll, associatedOffers);

  const template = doc.templatePricingCards;
  const displayEmptyLines = useMemo(() => {
    const displayEmptyLines: string[] = [];
    associatedOffers?.forEach((offer: any) => {
      const watchProperties = [
        'extraPrice',
        'installationLabel',
        'pricePerEmployeeLabel',
        'abonnementPrice',
      ];
      for (const property of watchProperties) {
        if (offer?.prices?.[property] && !displayEmptyLines?.includes(property))
          displayEmptyLines.push(property);
      }
    });
    return displayEmptyLines;
  }, [associatedOffers]);

  const swippable = useMemo(() => {
    if (associatedOffers.length >= 3 && ['xl'].includes(screen)) {
      return false;
    }
    return true;
  }, [associatedOffers.length, screen]);

  return (
    <Pricing>
      <div className="max-w-[612px] w-[90vw] mx-auto mb-36 lg:mb-40">
        {firstPayroll?.title && (
          <Pricing.BoxToggle name="firstPayroll" initialValue={false}>
            <div className="text-14 font-medium">{firstPayroll?.title}</div>
            <div className="text-12 text-neutral-101">
              {firstPayroll?.subtitle}
            </div>
          </Pricing.BoxToggle>
        )}
        {numberEmployeesInput?.min && (
          <Pricing.BoxSlider
            {...numberEmployeesInput}
            banner={
              atLeastOneDiscountOfferIsDisplayed &&
              doc?.conditionalBanner?.length > 0
                ? {
                    ...doc.conditionalBanner[0],
                    screenSize: screen,
                  }
                : undefined
            }
            name="nbEmployee"
          ></Pricing.BoxSlider>
        )}
        {buttonList && (
          <Pricing.BoxButton
            btns={buttonList}
            initialValue={initialValue}
            name="nbEmployee"
            label={numberEmployeesInputLabel}
          />
        )}
      </div>

      <div className="w-full">
        <Carousel
          isItemsCentered
          intitialSelected={1}
          swippable={swippable}
          paginationPosition="top"
          gap={!['lg', 'xl'].includes(screen) ? 8 : 24}
          classNameCarousel={classNames(
            'gap-8 lg:gap-24',
            associatedOffers.length >= 3 && 'xl:w-auto xl:!p-0 xl:!m-0',
            associatedOffers.length === 2 && 'lg:w-auto lg:!p-0 lg:!m-0',
          )}
          pagination={
            <PaginationDots
              activeColor="bg-neutral-101"
              inactiveColor="bg-neutral-51"
              className={classNames(
                associatedOffers.length >= 3 && 'xl:hidden',
                associatedOffers.length === 2 && 'lg:hidden',
              )}
            />
          }
        >
          {associatedOffers?.map(
            (associatedOffer: OffersAssociated, key: any) => {
              const cardOffer = hasDiscountOffers(associatedOffer)
                ? findDiscountOfferFromAssociatedOffers(
                    associatedOffer,
                    nbEmployee,
                  )
                : associatedOffer.offer;

              function getPreviousOffer(
                associatedOffer: OffersAssociated,
                nbEmployee: number,
              ) {
                const found = findDiscountOfferFromAssociatedOffers(
                  associatedOffer,
                  nbEmployee,
                );
                if (
                  hasDiscountOffers(associatedOffer) &&
                  found &&
                  found !== associatedOffer.offer
                ) {
                  return associatedOffer.offer;
                }
                return undefined;
              }

              return (
                <Card
                  key={key}
                  prefixTotal={prefixTotal}
                  sufixTotal={sufixTotal}
                  firstPayrollOffer={getFirstPayrollOffer(
                    associatedOffer.discountOffers ?? [],
                  )}
                  previousOffer={getPreviousOffer(associatedOffer, nbEmployee)}
                  offer={cardOffer}
                  isDisabled={cardsDisabled.includes(key)}
                  cta={cta}
                  displayEmptyLines={displayEmptyLines}
                  locale={locale}
                  index={key}
                  template={template}
                  prefixPricePerEmployee={prefixPricePerEmployee}
                  numberCards={associatedOffers?.length || 0}
                  hideTotalPrice={hideTotalPrice}
                />
              );
            },
          )}
        </Carousel>
      </div>
      {comparaisonTableLink && (
        <a
          href="#SectionComparisonTable"
          className="block text-blue-101 hover:text-blue-301 text-center w-fit m-auto font-sans text-16 my-[53px]"
          onClick={() => setOpenTablePrice(true)}
          onKeyDown={(e) => {
            if (e.code === 'enter') setOpenTablePrice(true);
          }}
        >
          {comparaisonTableLink}
        </a>
      )}
    </Pricing>
  );
};
