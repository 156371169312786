import React, { useContext } from 'react';
import { classNames } from '../../utils/style';
import { CarouselContext, CarouselContextType } from './CarouselContext';

type PaginationDotsProps = {
  className?: string;
  activeColor?: string;
  inactiveColor?: string;
  classNameDots?: string;
};

export const PaginationDots = ({
  className,
  classNameDots,
  activeColor,
  inactiveColor,
}: PaginationDotsProps) => {
  const {
    elementSelected,
    nbElement,
    paginationPosition,
    classNamePagination,
    onClickDot,
  } = useContext<CarouselContextType>(CarouselContext);
  return (
    <div
      className={classNames(
        className,
        'PaginationDots',
        'flex items-center',
        'mx-auto justify-center gap-8',
        paginationPosition === 'top' ? 'mb-20' : 'mt-20',
        nbElement <= 1 && 'hidden',
        classNamePagination,
      )}
    >
      {[...Array(nbElement)].map((value, index) => (
        <button
          key={index}
          className={classNames(
            'h-8 w-8 cursor-pointer',
            elementSelected === index ? activeColor : inactiveColor,
            classNameDots,
          )}
          style={{ borderRadius: '50%' }}
          onClick={() => onClickDot && onClickDot(index)}
          aria-label={`slide n°${index + 1}`}
        />
      ))}
    </div>
  );
};

export default PaginationDots;
